import * as React from "react"
import { graphql } from "gatsby"

import Seo from "../components/seo"
import Layout from "../components/layout"
import Footer from "../components/Footer"
import ComponentSelector from "../components/ComponentSelector"

export default function IndexPage({ data }) {
  const components = data?.datoCmsPage?.components
  const showFooterForm = data?.datoCmsPage?.showFooterForm

  return (
    <Layout>
      <Seo
        title={data?.datoCmsPage?.pageTitle}
        description={data?.page?.nodes[0].metadata?.description}
        image={data?.page?.nodes[0].metadata?.image?.url}
      />
      <ComponentSelector components={components} />
      <Footer showFooterForm={showFooterForm} />
    </Layout>
  )
}

export const query = graphql`
  query HomeQuery {
    datoCmsPage(slug: { eq: "home" }) {
      id
      pageTitle
      showFooterForm
      metadata {
        description
        title
        image {
          url
        }
        twitterCard
      }
      components {
        ... on DatoCmsBasicSlider {
          headline
          contentType {
            contentType
          }
          limit
          link {
            isThisAButton
            label
            linkUrl
          }
          tagToShow
          relatedFocusAreas {
            title
          }
          relatedResources {
            title
          }
          relatedProjects {
            title
          }
          showIntroText
          componentId
          background {
            backgroundColor
            hasBackgroundImage
            image {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          cardColor
          cardAlignment
        }
        ... on DatoCmsNarrativeMapSection {
          componentId
          headline
          intro
        }
        ... on DatoCmsStatisticsRow {
          componentId
          statistics {
            number
            description
          }
        }
        ... on DatoCmsHomeHeroHeader {
          componentId
          headline
          background {
            hasBackgroundImage
            backgroundColor
            image {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          intro
          everyActionFormLink
        }
        ... on DatoCmsFeatureSlider {
          componentId
          headline
          background {
            backgroundColor
          }
          featuredSlides {
            featureTag
            featuredImage {
              featuredImage {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            hasVideo
            headline
            intro
            textBorderColor
            videoLink
            videoSource
            button {
              linkUrl
              label
            }
          }
        }
        ... on DatoCmsFormCtaBanner {
          componentId
          headline
          description
          donationForm
          formHeadline
          formLink
          amountButtons {
            amount
          }
          recurringDonationOptions {
            recurringDonation
          }
          image {
            gatsbyImageData(placeholder: BLURRED)
          }
          rightBackground {
            hasBackgroundImage
            backgroundColor
            # image {
            #   gatsbyImageData(placeholder: BLURRED)
            # }
            image {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                # height: 105
              )
            }
          }
          leftBackground {
            hasBackgroundImage
            backgroundColor
            image {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        ... on DatoCmsCuratorSection {
          componentId
          curatorFeedId
          socialMediaInfo {
            socialMediaItem {
              socialMediaId
              socialMediaLink
            }
          }
        }
      }
    }
  }
`
